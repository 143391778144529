import React, { useEffect, useState } from "react";
import { getAppliedDscAdmin, getAppliedDscView ,updateDscApply} from "api/services/applyDsc";
import { ResType } from "types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import TextFieldWithCopy from "components/TextFieldWithCopy";
import { Box, Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { DscApplicationStatus } from "utils/constants";
import { getTitle } from "utils";
import { snack } from "components/toast";

const ViewDSCApply = () => {
  const params = useParams();

  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [search, setSearch] = useState("");
  const queryClient = useQueryClient();

 

  const { isLoading, data, error }: ResType = useQuery(
    ["dsc-register", params.id],
    getAppliedDscView,
    { retry: false }
  );
  const [state, setState] : any=useState(data?.data || {})


  useEffect(()=>{
setState(data?.data)
  },[data])



  const { mutate } = useMutation(updateDscApply, {
    onSuccess: () => {
      snack.success("DSC Application Updated");    
    },
    onError: (err: any) => {
      snack.error(err.response.data.message);
    },
  });
  const handleSubmit=()=>{
    // updateDscApply
    mutate({id: state?.id, data: state})
  }

  return (
    <Box mt={2} margin={2}>
      <Typography color="primary" variant="h6" sx={{ mb: 2 }}>
        Information
      </Typography>
      <Box>
        <Grid container spacing={4}>
        <Grid item xs={12}>
            <TextField
              label="Organization Name"
              disabled
              value={data?.data?.organization?.legalName || ""}
              variant="outlined"
              size="small"
              sx={{minWidth:"500px"}}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Applicant Name"
              disabled
              value={data?.data?.applicantName || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Mobile Number"
              disabled
              value={data?.data?.mobileNumber || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
         
          <Grid item xs={4}>
            <TextField
              label="Email"
              disabled
              value={data?.data?.email || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="selected type"
              disabled
              value={data?.data?.selectedType || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Certificate Type"
              disabled
              value={data?.data?.certificateType || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Certificate Validity"
              disabled
              value={data?.data?.certificateValidity || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldWithCopy
              label="photo File Url"
              required
              value={data?.data?.dscPhoto?.fileUrl}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldWithCopy
              label="aadhar File Url"
              required
              value={data?.data?.dscAadhar?.fileUrl}
              disabled
            />
          </Grid>
          <Grid item xs={4}>
            <TextFieldWithCopy
              label="Pan File Url"
              required
              value={data?.data?.dscPan?.fileUrl}
              disabled
            />
          </Grid>
        
       
          <Grid item xs={4}>

            
          <TextField
            select
            label="Application Status"
            fullWidth
            size="small"
            name="applicationStatus"
            value={state?.applicationStatus}
            onChange={(e: any) => {
              setState({ ...state, applicationStatus: e.target.value });
            }}
          >
            {Object.values(DscApplicationStatus).map((item, index) => (
              <MenuItem key={index} value={item}>
                {getTitle(item)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        
        </Grid>
        <Button
            onClick={handleSubmit}
            size="large"
            color="secondary"
            variant="contained"
            sx={{marginTop:"20px"}}
          >
            Submit
          </Button>
      </Box>
  
    </Box>
  );
};

export default ViewDSCApply;
